import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faEye, faEyeSlash, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { Asterisk } from 'lucide-react';

class Input extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    inputLabelClassName: PropTypes.string,
    defaultValue: PropTypes.any,
    type: PropTypes.string,
    precision: PropTypes.number,
    step: PropTypes.number,
    tabIndex: PropTypes.string,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    placeholder: PropTypes.string,
    icon: PropTypes.object,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onKeyDown: PropTypes.func,
    disabled: PropTypes.bool,
    noMargin: PropTypes.bool,
    required: PropTypes.bool,
    readOnly: PropTypes.bool,
    center: PropTypes.bool,
    rightAlign: PropTypes.bool,
    color: PropTypes.oneOf(['dark', 'light']),
    min: PropTypes.number,
    max: PropTypes.number,
    data: PropTypes.any,
    highlighted: PropTypes.bool,
    autoFocus: PropTypes.bool,
    ref: PropTypes.any,
    autoComplete: PropTypes.string,
    allowPasswordToggle: PropTypes.bool,
    allowClear: PropTypes.bool,
    onClear: PropTypes.func,
  }

  static defaultProps = {
    type: 'text',
    defaultValue: '',
    placeholder: '',
    tabIndex: '',
    name: '',
    color: 'dark',
    readOnly: false,
    onChange: function () { },
    onClick: function () { },
    onKeyDown: function () { },
    onBlur: () => { },
    onFocus: () => { },
    noMargin: false,
    disabled: false,
    center: false,
    rightAlign: false,
    required: false,
    highlighted: false,
    autoFocus: false,
    allowPasswordToggle: false,
    allowClear: false,
    onClear: () => { },
    precision: 0,
  }

  constructor(props) {
    super(props);
    // All this because Javascript treats 0 and undefined the same way.
    // 0's will now show up if passed into a number input
    let value = props.value || props.defaultValue || '';
    if (props.type === 'number' && !isNaN(parseFloat(value))) {
      // Use the precision property to determine decimal places
      const exponent = Math.pow(10, props.precision);
      value = (parseInt(parseFloat(value) * exponent) / exponent).toString();
    }

    // Ensure we always have a ref value, even if not passed in.
    this.ref = props.forwardedRef || React.createRef();

    this.state = {
      value,
      hidden: true,
      type: props.type || 'text',
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== undefined && props.value !== state.value) {
      return {
        value: props.value
      };
    } else if (props.type === 'email' && ( props.value || props.value === '' )) {
      // Prevent using value for email type because it doesn't support setSelectionRange
      throw new Error('Input element’s type (’email’) does not support this props (’value’).');
    }
    return null;
  }

  componentDidUpdate() {
    const { type, selectionStart, selectionEnd, value } = this.state;
    const input = this.ref.current;
    // Method setSelectionRange is applied only to the input elements with the types text, search, URL, tel and password.
    if (input && ['text', 'tel', 'url'].includes(type) && value) {
      // Set cursor to tracked state
      input.setSelectionRange(selectionStart, selectionEnd);
    }
  }

  get classNames() {
    const { className, icon, noMargin, color, highlighted, allowClear, allowPasswordToggle } = this.props;
    return classNames('relative input-field', {
      [className]: className,
      'is-light': color === 'light',
      'input-icon-container': icon || allowClear || allowPasswordToggle,
      'highlighted': highlighted,
      'margin-bottom-0': noMargin,
    });
  }

  get inputClassNames() {
    const { color, center, rightAlign, inputClassName, icon } = this.props;

    return classNames({
      'is-light': color === 'light',
      'center-align': center,
      'right-align': rightAlign,
      'padding-left-35': icon,
      [inputClassName]: inputClassName,
    });
  }

  get showPasswordIcon() {
    const { allowPasswordToggle } = this.props;
    const { type } = this.state;
    return (allowPasswordToggle && ['text', 'password'].includes(type));
  }

  handleChange = (e) => {
    e.persist();
    const { min, max, onChange } = this.props;
    const { type } = this.state;
    let { value } = e.target;

    if (type === 'number') {
      if (!isNaN(min) && min > value) {
        value = '';
      }

      if (!isNaN(max) && max < value) {
        value = max;
      }
    }

    // Get current cursor status
    const selectionStart = e.target.selectionStart;
    const selectionEnd = e.target.selectionEnd;

    this.setState({
      value,
      selectionStart,
      selectionEnd,
    }, () => onChange(e, value));
  }

  handleTogglePassword = (e) => {
    e && e.preventDefault();
    const { hidden } = this.state;
    if (hidden) {
      this.setState({
        hidden: false,
        type: 'text',
      })
    } else {
      this.setState({
        hidden: true,
        type: 'password',
      })
    }
  }

  handleClear = (e) => {
    e && e.preventDefault();
    const { onClear } = this.props;
    onClear();
  }

  render() {
    const {
      label, id, name, errorMessage, icon, readOnly, tabIndex,
      data, placeholder, onClick, disabled, required, onKeyDown, max, min,
      autoFocus, onBlur, onFocus, autoComplete, step, allowClear,
      inputLabelClassName,
    } = this.props;
    const { value, type, hidden } = this.state;

    return (
      <div className={this.classNames}>
        {
          label &&
          <label htmlFor={id} className={classNames('input-label', {
            [inputLabelClassName]: inputLabelClassName,
          })}>
            {label}
            {
              required &&
              <span><Asterisk className='danger' size='14' /></span>
            }
          </label>
        }
        {
          icon &&
          <FontAwesomeIcon
            icon={icon}
            className={classNames('input-icon left', {
            'disabled': disabled,
            'top-9': !label,
            'top-47': label,
            })}
          />
        }
        {
          this.showPasswordIcon &&
          <a onClick={this.handleTogglePassword}>
            <FontAwesomeIcon
              icon={hidden ? faEyeSlash : faEye}
              className={classNames('input-icon right', {
                'disabled': disabled,
                'top-15': !label,
                'top-50': label,
                })}
              size='lg'
            />
          </a>
        }
        {
          ( allowClear && value ) &&
          <a onClick={this.handleClear}>
            <FontAwesomeIcon
              icon={faTimes}
              className={classNames('input-icon right', {
                'disabled': disabled,
                'top-15': !label,
                'top-40': label,
              })}
              size='sm'
            />
          </a>
        }
        <input
          {...{
            type, name, id, value, tabIndex,
            placeholder, readOnly, disabled,
            required, onKeyDown, onClick,
            min, max, autoFocus, onBlur, onFocus,
            autoComplete, step,
          }}
          ref={this.ref}
          data-data={data}
          className={this.inputClassNames}
          onChange={this.handleChange}
          value={value}
        />
        {
          errorMessage &&
          <div className='error-message'>
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className='margin-right-5'
            />
            {errorMessage}
          </div>
        }
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <Input {...props} forwardedRef={ref} />
));